.full-page {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  background: white;
  z-index: 10001;

  overflow: auto;
}

.tabComponent {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 24px;
}

.financePage {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
}

.financeButton {
  background: #8a2525 !important;
  border-color: #8a2525 !important;
}

.financeButton:hover {
  background: #781b1b !important;
  border-color: #781b1b !important;
}

.financeBillButton {
  background: #d91515 !important;
  border-color: #d91515 !important;
}

.financeBillButton:hover {
  background: #8a2525 !important;
  border-color: #8a2525 !important;
}

.financeTransactionButton {
  background: #037f0c !important;
  border-color: #037f0c !important;
}

.financeTransactionButton:hover {
  background: #026409 !important;
  border-color: #026409 !important;
}