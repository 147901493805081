.MySites {
  padding: 20px 20px 0;
}

.MySites textarea {
  min-height: 300px;
}

.button-tray-bottom {
  z-index: 1000;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 45px;

  background: #3333;
}
