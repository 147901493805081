.space-around {
  display: flex;
  justify-content: space-around;
}

.vertical-space-20 {
  height: 20px;
}

.react-app h2::after {
  content: none;
}


.due-amount {
  font-weight: bold;
  color: #692020;
}

.paid-amount,
.succeeded-amount
{
  font-weight: bold;
  color: #206920;
}

.pending-amount {
  font-weight: bold;
  color: #202020;
}

.finance-table input[type="search"] + span {
  top: 0 !important;
  height: 100%;
}

.finance-table input[type="search"] + span > button {
  height: 100%;
}
